
import reviewedList from './list'
import reviewedInformation from './information'
import reviewedAlipay from './alipay'
import reviewedWeChat from './wechat'
import setting from "@/router/pages/admin/reviewed/setting";

const reviewed = {
  path: '/reviewed',
  component: resolve => require(['@/pages/admin/reviewed'], resolve),
  redirect: '/reviewed/list',
  children: [
    reviewedList,
    reviewedInformation,
    reviewedAlipay,
    reviewedWeChat,
      setting
  ],
  meta: {
    requireAuth: true
  }
}

export default reviewed
