import Vue from 'vue'
import App from './App'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import router from './router'
import store from './store'
import Print from 'vue-print-nb' // 打印
import VueCropper from 'vue-cropper' //裁剪图片
import axios from 'axios'
import {WSS_URL} from "@/config"
import './until/directive.js'

Vue.config.productionTip = false
/* 引入element-ui，指定尺寸以及弹窗的默认z-index */
Vue.use(ElementUI, {
  size: 'small',
  zIndex: 3000
})

Vue.use(Print);
Vue.use(VueCropper);

/* axios设置 */
import {
  ajax
} from '@/until/httpApi'
Vue.prototype.$ajax = ajax;

Vue.prototype.$extend = function () {
  return Object.assign({}, ...arguments)
}
/* 支持全局事件 */
const bus = new Vue()
Vue.prototype.$bus = bus
// 添加拦截器
axios.interceptors.request.use(config => {
  NProgress.start()
  config.headers.Authorization = localStorage.getItem('token')
  return config
})
axios.interceptors.response.use(config => {
  NProgress.done()
  return config
})
const utype = localStorage.getItem('utype');

if (utype) {
  window.utype = utype;
}

// 路由判断登录 根据路由配置文件的参数
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireAuth)) { // 判断该路由是否需要登录权限
    if (localStorage.getItem('token')) { // 判断当前的token是否存在 ； 登录存入的token
      next();

    } else {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        } // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
    }
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

console.log('adminName',localStorage.getItem('adminName'))

//定时器
Vue.prototype.$reconnectTimeout = null
	
//连接socket	
Vue.prototype.$connectWebSocket = function () {
	// WebSocket配置
	const wsServer = WSS_URL;
	// const wsServer = 'ws://127.0.0.1:7104';
	
	// 创建WebSocket实例
	const ws = new WebSocket(wsServer);
	 
	// 监听WebSocket的打开事件
	ws.addEventListener('open', function (event) {
	  console.log('WebSocket 连接已打开', event);
	});
	 
	// 监听WebSocket的消息事件
	ws.addEventListener('message', function (event) {
		console.log('接收到消息', event);
		var data = JSON.parse(event.data) || {}
		if(data.pushAccount){
			var pushAccount = data.pushAccount.split(",");
			if(pushAccount.indexOf(localStorage.getItem('adminName')) === -1){
				return false;
			}
		}
		//服务产品订单推送
		if(data.pushType == 'productOrderPush'){
			let notification = Vue.prototype.$notify({
				title: data.title,
				position: 'bottom-right',
				dangerouslyUseHTMLString: true,
				message: data.message,
				duration: 0,
				onClick:function(){
					console.log(data.path)
					if(data.path){
						router.push(data.path)
					}
					//关闭通知
					notification.close();
				}
			});
		}
	});
	 
	// 监听WebSocket的错误事件
	ws.addEventListener('error', function (event) {
	  console.log('WebSocket 出现错误', event);
	  Vue.prototype.$reconnectWebSocket();
	});
	 
	// 监听WebSocket的关闭事件
	ws.addEventListener('close', function (event) {
	  console.log('WebSocket 连接已关闭', event);
	  Vue.prototype.$reconnectWebSocket();
	});
}
Vue.prototype.$connectWebSocket();
//重新连接socket	
Vue.prototype.$reconnectWebSocket = function () {
  if (Vue.prototype.$reconnectTimeout) {
	clearTimeout(Vue.prototype.$reconnectTimeout);
  }
  Vue.prototype.$reconnectTimeout = setTimeout(() => {
	Vue.prototype.$connectWebSocket();
  }, 5000);
}