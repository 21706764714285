
import signingHome from './home'
import signingInformation from './information'
import signingAlipay from './yftAlipay'
import signingWeChat from './yftWeChat'
import signingshopCodeAlipay from './shopCodeAlipay'

const signing = {
  path: 'signing/:merchantId',
  component: resolve => require(['@/pages/admin/merchant/signing'], resolve),
  redirect: 'signing/:merchantId/home',
  children: [
    signingHome,
    signingInformation,
    signingAlipay,
    signingWeChat,
    signingshopCodeAlipay
  ]
}

export default signing
